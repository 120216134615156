import { graphql } from "gatsby";
import * as React from "react";

import Layout from "../../components/Layout";
import PostPreview from "../../components/PostPreview";

export interface Props {
  data: {
    site: {
      siteMetadata: {
        status: string;
      };
    };
    bio: {
      html: string;
    };
    allMarkdownRemark: {
      edges: Array<{
        node: {
          id: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            author: string;
            description: string;
            lang: string;
            templateKey: string;
            published: string;
            publishedDate: string;
            tags: string[];
          };
        };
      }>;
    };
  };
}

const BlogPage: React.SFC<Props> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(p => <PostPreview key={p.node.id} post={p.node} />);

  return <Layout>
    <div className="hero is-primary is-bold">
      <div className="hero-body white-hero-body">
        <section className="container is-narrow">
          <h1 className="title">Blog</h1>
        </section>
      </div>
    </div>
    <div className="container is-narrow">
      <section className="section">
        <ul className="post-list">{posts}</ul>
      </section>
    </div>
  </Layout>;
};

export default BlogPage;

export const query = graphql`
  query BlogPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___published] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            lang
            description
            templateKey
            published(formatString: "MMMM DD, YYYY")
            publishedDate: published(formatString: "YYYY-MM-DD")
            tags
          }
        }
      }
    }
  }
`;
